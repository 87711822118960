<template>
    <div style="width: 1000px;min-height:100vh;display: flex;justify-content: center;align-items: center;margin: 0 auto;">
        <!--  左边 -->
        <div style="width: 660px;height: 500px;background-color: #FFFFFF">
            <div style="clear: both;display: flex;align-items: center;padding-top: 60px;">
                <div style="font-size: 25px;color:#173191;font-weight:bold;display: flex;justify-content: center;flex: 1;">甲方联系单</div>
                <div style="position: relative;">
                    <img class="right op sc hand" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/log2.png" style="width: 50px;height: 20px;position: absolute;right: 60px;top: -10px">
                </div>
            </div>
            <div style="clear: both;overflow: hidden" >
                <div style="margin-top: 30px;margin-left: 30px;float: left">
                    <div class="title">委托项目名称</div>
                    <div class="center">{{content.fd_1702612678332}}</div>
                    <div class="underline"><el-divider></el-divider></div>
                </div>
                <div style="margin-top: 30px;margin-left: 30px;float: left">
                    <div class="title">项目日期</div>
                    <div class="center">{{content.fd_1702612753459}}</div>
                    <div class="underlink"><el-divider></el-divider></div>
                </div>
                <div style="margin-top: 30px;margin-left: 30px;float: left">
                    <div class="title">甲方单位</div>
                    <div class="center">{{content.fd_1702612809124}}</div>
                    <div class="underline"><el-divider></el-divider></div>
                </div>
                <div style="margin-top: 30px;margin-left: 30px;float: left">
                    <div class="title">甲方职务</div>
                    <div class="center">{{content.fd_1702612805341}}</div>
                    <div class="underlink"><el-divider></el-divider></div>
                </div>
                <div style="margin-top: 25px;margin-left: 30px;float: left">
                    <div class="title">备注信息：</div>
                    <div class="rolling" style="width: 580px;">{{content.fd_1702612784002}}</div>
                    <div style="width: 580px;margin-top: -10px"><el-divider></el-divider></div>
                </div>
            </div>
        </div>
        <!--  右边   -->
        <div style="width: 340px;height: 500px; background:#031F88">
            <div style="width: 100%;height: 100%;background: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/background.png')">
                <div  style="display: flex;margin-left: 40px;padding-top:50px">
                    <div style="margin-right: 10px;">
                        <img class="img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/thatperson.png">
                    </div>
                    <div class="thatpersonname">甲方姓名：</div>
                    <div class="thatpersonname">{{content.fd_1677729356192}}</div>
                </div>
                <div  style="display: flex;margin-left: 40px;padding-top:45px">
                    <div style="margin-right: 10px;">
                        <img class="img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/mobilephone.png">
                    </div>
                    <div class="thatpersonname">联系方式：</div>
                    <div class="thatpersonname">{{content.fd_1702612766061}}</div>
                </div>
                <div  style="display: flex;margin-left: 40px;padding-top:45px">
                    <div style="margin-right: 10px;">
                        <img class="img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/Landline.png">
                    </div>
                    <div class="thatpersonname">座机：</div>
                    <div class="thatpersonname">{{content.fd_1702612803230}}</div>
                </div>
                <div  style="display: flex;margin-left: 40px;padding-top:45px">
                    <div style="margin-right: 10px;">
                        <img class="img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/mailbox.png">
                    </div>
                    <div class="thatpersonname">邮箱：</div>
                    <div class="thatpersonname">{{content.fd_1702612797403}}</div>
                </div>
                <div  style="display: flex;margin-left: 40px;padding-top:45px">
                    <div style="margin-right: 10px;">
                        <img class="img" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/QQ.png">
                    </div>
                    <div class="thatpersonname">QQ：</div>
                    <div class="thatpersonname">{{content.fd_1702612767949}}</div>
                </div>
                <div  style="display: flex;margin-left: 40px;padding-top:45px">
                    <div style="margin-right: 10px;">
                        <img class="img" style="width: 20px;" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/VX.png">
                    </div>
                    <div class="thatpersonname">微信：</div>
                    <div class="thatpersonname">{{content.fd_1702612800124}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },

    computed: {

    },
    data () {
        return {
            content:{},
        }
    },
    mounted:function(){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.getRoomContent()
    },
    methods:{
        // 获取内容
        getRoomContent() {
            this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
                this.content = res.data.content;
            })
        },

    }
}
</script>
<style scoped>
.title {
    color:#999999;
    font-size: 16px;
    height: 26px;
    font-family: "SimHei";
    font-weight:bold;
}
.center {
    color:#444444;
    font-size: 17px;
    margin-top: 10px;
    font-weight:bold;
    font-family: "FangSong";

}
.rolling {
    color:#444444;
    font-size: 17px;
    margin-top: 10px;
    font-weight:bold;
    font-family: "FangSong";
    max-height: 78px;
    overflow-y: scroll;
}

.rolling::-webkit-scrollbar {
    display: none;
}

.underline {
    width: 340px;
    margin-top: -10px;
}
.underlink {
    width: 200px;
    margin-top: -10px
}
.img {
    width: 18px;
    height:16px;
    margin-top: 4px;
}
.thatpersonname {
    font-size: 16px;
    color: #FFFFFF;
    font-weight:bold;
}
</style>